var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    {
      staticClass: "tw-max-w-none tw-p-16",
      staticStyle: { height: "calc(100% - 26px)" },
      attrs: { "grid-list-lg": "" }
    },
    [
      !_vm.project
        ? _c(
            "div",
            [
              _c(
                "v-layout",
                [
                  _c(
                    "v-flex",
                    { attrs: { xs12: "", "text-xs-center": "" } },
                    [
                      _vm.isLoading.page
                        ? _c("LoaderCard", {
                            attrs: {
                              flat: "",
                              type: "spinner--center",
                              minHeight: "300px"
                            }
                          })
                        : _vm._e(),
                      !_vm.isLoading.page
                        ? _c("h2", { staticClass: "fadedText" }, [
                            _vm._v("Geen project gevonden")
                          ])
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.project
        ? _c(
            "v-layout",
            { staticClass: "tw-h-full", attrs: { row: "", wrap: "" } },
            [
              _c("v-flex", { attrs: { xs10: "" } }, [
                _c("h2", { staticClass: "elementSubTitle" }, [
                  _vm._v(_vm._s(_vm.project.name))
                ])
              ]),
              _vm.$store.state.isBETA && _vm.canUpdateProjects
                ? _c(
                    "v-flex",
                    { attrs: { xs2: "", "text-right": "" } },
                    [
                      _c(
                        "router-link",
                        { attrs: { to: "/project-manager/" + _vm.project.id } },
                        [_c("v-icon", [_vm._v("settings")])],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-flex",
                { staticClass: "tw-h-full tw-p-0", attrs: { xs12: "" } },
                [
                  _c(
                    "v-tabs",
                    {
                      attrs: { "hide-slider": "" },
                      model: {
                        value: _vm.activeTab,
                        callback: function($$v) {
                          _vm.activeTab = $$v
                        },
                        expression: "activeTab"
                      }
                    },
                    [
                      _vm._l(_vm.tabs, function(tab, index) {
                        return [
                          _c(
                            "v-tab",
                            {
                              key: index,
                              staticClass: "tab__filled",
                              class: { active: _vm.activeTab === tab.key },
                              attrs: { href: "#" + tab.key },
                              on: {
                                click: function($event) {
                                  return _vm.onTabClicked(tab.key)
                                }
                              }
                            },
                            [_vm._v(" " + _vm._s(tab.name) + " ")]
                          )
                        ]
                      })
                    ],
                    2
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "elementPanel tw-overflow-auto",
                      class: { "d-none": _vm.activeTab !== "planning" },
                      staticStyle: { height: "calc(100% - 48px)" }
                    },
                    [
                      _c(
                        "v-layout",
                        { staticClass: "tw-h-full", attrs: { wrap: "" } },
                        [
                          !_vm.isLoading.project
                            ? _c(
                                "v-flex",
                                {
                                  staticClass: "tw-overflow-auto",
                                  staticStyle: { height: "calc(100% - 48px)" },
                                  attrs: { xs12: "", md6: "" }
                                },
                                [
                                  _c(
                                    "v-layout",
                                    { attrs: { wrap: "" } },
                                    [
                                      _c(
                                        "v-flex",
                                        {
                                          staticClass:
                                            "custom-filter custom-filter--autocomplete",
                                          attrs: { xs12: "", shrink: "" }
                                        },
                                        [
                                          _c("ReportPlanningStatusFilter", {
                                            attrs: { value: "unplanned" },
                                            model: {
                                              value:
                                                _vm.planningTableFilters
                                                  .planning_status,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.planningTableFilters,
                                                  "planning_status",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "planningTableFilters.planning_status"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        { attrs: { xs12: "" } },
                                        [
                                          _vm.isLoading.reports
                                            ? _c("LoaderCard", {
                                                attrs: {
                                                  flat: "",
                                                  type: "spinner--center",
                                                  minHeight: "250px"
                                                }
                                              })
                                            : _vm._e(),
                                          _c("ReportDatatable", {
                                            ref: "reportDatatable",
                                            attrs: {
                                              limit: 10,
                                              dataTableFilters:
                                                _vm.planningTableFilters,
                                              autoRefetch: false,
                                              sort: [
                                                "applicant_submitted_at",
                                                "ASC"
                                              ],
                                              queryStatus: "project_planning"
                                            },
                                            on: {
                                              onMouseEnterTableRow:
                                                _vm.onMouseEnterTableRow
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "v-flex",
                            {
                              staticClass: "tw-p-0",
                              attrs: { xs12: "", md6: "" }
                            },
                            [
                              _c("PeTAT", {
                                attrs: {
                                  defaultFilters: _vm.petatFiltersPlanning,
                                  defaultIncludes: {
                                    earthquakes: 0,
                                    gasfields: 0,
                                    gasfields_6km: 0,
                                    reports: 0
                                  },
                                  legendaType: _vm.legendaType,
                                  response: _vm.response,
                                  isLoading: _vm.isLoading.petat,
                                  project: _vm.project,
                                  defaultZoom: _vm.project.map_settings.zoom,
                                  mapCenter: {
                                    lat:
                                      _vm.project.map_settings.center.latitude,
                                    lng:
                                      _vm.project.map_settings.center.longitude
                                  },
                                  mapOnly: true,
                                  highlighted: _vm.highlightedReport
                                },
                                on: {
                                  initialize: _vm.initializeEarthquakes,
                                  "update:legendaType": function($event) {
                                    _vm.legendaType = $event
                                  },
                                  "update:legenda-type": function($event) {
                                    _vm.legendaType = $event
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "elementPanel tw-p-0 tw-overflow-auto",
                      class: { "d-none": _vm.activeTab !== "in_progress" },
                      staticStyle: { height: "calc(100% - 48px)" }
                    },
                    [
                      _c(
                        "v-layout",
                        { attrs: { wrap: "" } },
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs12: "" } },
                            [
                              _c("WeekPlanningComponent", {
                                attrs: { project: _vm.project }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "elementPanel tw-overflow-auto",
                      class: { "d-none": _vm.activeTab !== "reports" },
                      staticStyle: { height: "calc(100% - 48px)" }
                    },
                    [
                      _c(
                        "v-layout",
                        { attrs: { wrap: "" } },
                        [
                          _c(
                            "v-flex",
                            { attrs: { shrink: "" } },
                            [
                              _c("v-select", {
                                attrs: {
                                  items: _vm.statuses,
                                  label: "Status",
                                  "item-value": "value",
                                  "item-text": "label"
                                },
                                model: {
                                  value: _vm.selectedStatus,
                                  callback: function($$v) {
                                    _vm.selectedStatus = $$v
                                  },
                                  expression: "selectedStatus"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs12: "" } },
                            [
                              _vm.isLoading.reports
                                ? _c("LoaderCard", {
                                    attrs: {
                                      flat: "",
                                      type: "spinner--center",
                                      minHeight: "250px"
                                    }
                                  })
                                : _vm._e(),
                              _vm.activeTab === "reports"
                                ? _c("ReportDatatable", {
                                    ref: "dataTableFilters",
                                    attrs: {
                                      dataTableFilters: _vm.dataTableFilters
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "elementPanel tw-overflow-auto",
                      class: { "d-none": _vm.activeTab !== "statistics" },
                      staticStyle: { height: "calc(100% - 48px)" }
                    },
                    [
                      _c(
                        "v-layout",
                        { attrs: { wrap: "" } },
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs12: "" } },
                            [
                              _vm.isLoading.statistics
                                ? _c("LoaderCard", {
                                    attrs: {
                                      flat: "",
                                      type: "spinner--center",
                                      minHeight: "300px"
                                    }
                                  })
                                : _vm._e(),
                              !_vm.isLoading.statistics
                                ? [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "tw-flex-wrap tw-flex tw-flex-row"
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "tw-w-1/2 lg:tw-w-1/3"
                                          },
                                          [
                                            _vm.project &&
                                            _vm.project.statistics
                                              ? _c("StatisticsProgressWidget", {
                                                  attrs: {
                                                    item: {
                                                      percentage:
                                                        _vm.project.statistics
                                                          .reports.percentage
                                                          .planned || 0,
                                                      value:
                                                        _vm.project.statistics
                                                          .reports.total_count
                                                          .planned || 0,
                                                      target:
                                                        _vm.project.statistics
                                                          .reports.total_count
                                                          .all || 0
                                                    },
                                                    label: "Ingepland",
                                                    warningBoundry: 90,
                                                    okBoundry: 100
                                                  }
                                                })
                                              : _vm._e()
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "tw-w-1/2 lg:tw-w-1/3"
                                          },
                                          [
                                            _vm.project &&
                                            _vm.project.statistics
                                              ? _c("StatisticsProgressWidget", {
                                                  attrs: {
                                                    item: {
                                                      percentage:
                                                        _vm.project.statistics
                                                          .reports.percentage
                                                          .approved || 0,
                                                      value:
                                                        _vm.project.statistics
                                                          .reports.total_count
                                                          .approved || 0,
                                                      target:
                                                        _vm.project.statistics
                                                          .reports.total_count
                                                          .all || 0
                                                    },
                                                    label: "Gelopen",
                                                    warningBoundry: 90,
                                                    okBoundry: 100
                                                  }
                                                })
                                              : _vm._e()
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "tw-w-1/2 lg:tw-w-1/3"
                                          },
                                          [
                                            _vm.project &&
                                            _vm.project.statistics
                                              ? _c("StatisticsProgressWidget", {
                                                  attrs: {
                                                    item: {
                                                      percentage:
                                                        _vm.project.statistics
                                                          .reports.percentage
                                                          .downloaded || 0,
                                                      value:
                                                        _vm.project.statistics
                                                          .reports.total_count
                                                          .downloaded || 0,
                                                      target:
                                                        _vm.project.statistics
                                                          .reports.total_count
                                                          .all || 0
                                                    },
                                                    label:
                                                      "Verstuurd aan aanvrager",
                                                    warningBoundry: 90,
                                                    okBoundry: 100
                                                  }
                                                })
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      ]
                                    ),
                                    _c("div", {
                                      staticClass:
                                        "tw-h-px tw-w-full tw-bg-gray-200 tw-my-64"
                                    }),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "tw-flex tw-flex-row tw-space-x-0 tw-flex-wrap"
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "tw-w-full" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "tw-text-2xl tw-font-semibold tw-mb-8"
                                              },
                                              [_vm._v("Per partij")]
                                            ),
                                            _vm.statisticsOrganizationItems &&
                                            _vm.statisticsOrganizationItems
                                              .length
                                              ? _c("List", {
                                                  attrs: {
                                                    labels:
                                                      _vm.statisticsColumns,
                                                    items:
                                                      _vm.statisticsOrganizationItems
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "name",
                                                        fn: function(item) {
                                                          return [
                                                            _c(
                                                              "div",
                                                              {
                                                                class: {
                                                                  "tw-font-bold":
                                                                    item.isTotals
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.name
                                                                  )
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        }
                                                      },
                                                      {
                                                        key: "report_count",
                                                        fn: function(item) {
                                                          return [
                                                            _c(
                                                              "div",
                                                              {
                                                                class: {
                                                                  "tw-font-bold":
                                                                    item.isTotals
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.report_count
                                                                  )
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        }
                                                      },
                                                      {
                                                        key: "damage_count",
                                                        fn: function(item) {
                                                          return [
                                                            _c(
                                                              "div",
                                                              {
                                                                class: {
                                                                  "tw-font-bold":
                                                                    item.isTotals
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.damage_count
                                                                  )
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        }
                                                      },
                                                      {
                                                        key:
                                                          "total_causal_damages",
                                                        fn: function(item) {
                                                          return [
                                                            _c(
                                                              "div",
                                                              {
                                                                class: {
                                                                  "tw-font-bold":
                                                                    item.isTotals
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.total_causal_damages
                                                                  )
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        }
                                                      },
                                                      {
                                                        key:
                                                          "average_percentage_causal_damages",
                                                        fn: function(item) {
                                                          return [
                                                            _c(
                                                              "div",
                                                              {
                                                                class: {
                                                                  "tw-font-bold":
                                                                    item.isTotals
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      item.average_percentage_causal_damages
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        }
                                                      },
                                                      {
                                                        key:
                                                          "average_damages_per_report",
                                                        fn: function(item) {
                                                          return [
                                                            _c(
                                                              "div",
                                                              {
                                                                class: {
                                                                  "tw-font-bold":
                                                                    item.isTotals
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.average_damages_per_report
                                                                  )
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        }
                                                      },
                                                      {
                                                        key: "payout_average",
                                                        fn: function(item) {
                                                          return [
                                                            _c(
                                                              "div",
                                                              {
                                                                class: {
                                                                  "tw-font-bold":
                                                                    item.isTotals
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.payout_average
                                                                  )
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    false,
                                                    4159919673
                                                  )
                                                })
                                              : _c("div", [
                                                  _vm._v(
                                                    "Geen resultaten gevonden."
                                                  )
                                                ])
                                          ],
                                          1
                                        ),
                                        _c("div", {
                                          staticClass:
                                            "tw-h-px tw-w-full tw-bg-gray-200 tw-my-64"
                                        }),
                                        _c(
                                          "div",
                                          { staticClass: "tw-w-full" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "tw-text-2xl tw-font-semibold tw-mb-8"
                                              },
                                              [_vm._v("Per type woning")]
                                            ),
                                            _vm.statisticsHousingTypeItems &&
                                            _vm.statisticsHousingTypeItems
                                              .length
                                              ? _c("List", {
                                                  attrs: {
                                                    labels:
                                                      _vm.statisticsColumns,
                                                    items:
                                                      _vm.statisticsHousingTypeItems
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "name",
                                                        fn: function(item) {
                                                          return [
                                                            _c(
                                                              "div",
                                                              {
                                                                class: {
                                                                  "tw-font-bold":
                                                                    item.isTotals
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.name
                                                                  )
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        }
                                                      },
                                                      {
                                                        key: "report_count",
                                                        fn: function(item) {
                                                          return [
                                                            _c(
                                                              "div",
                                                              {
                                                                class: {
                                                                  "tw-font-bold":
                                                                    item.isTotals
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.report_count
                                                                  )
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        }
                                                      },
                                                      {
                                                        key: "damage_count",
                                                        fn: function(item) {
                                                          return [
                                                            _c(
                                                              "div",
                                                              {
                                                                class: {
                                                                  "tw-font-bold":
                                                                    item.isTotals
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.damage_count
                                                                  )
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        }
                                                      },
                                                      {
                                                        key:
                                                          "total_causal_damages",
                                                        fn: function(item) {
                                                          return [
                                                            _c(
                                                              "div",
                                                              {
                                                                class: {
                                                                  "tw-font-bold":
                                                                    item.isTotals
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.total_causal_damages
                                                                  )
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        }
                                                      },
                                                      {
                                                        key:
                                                          "average_percentage_causal_damages",
                                                        fn: function(item) {
                                                          return [
                                                            _c(
                                                              "div",
                                                              {
                                                                class: {
                                                                  "tw-font-bold":
                                                                    item.isTotals
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      item.average_percentage_causal_damages
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        }
                                                      },
                                                      {
                                                        key:
                                                          "average_damages_per_report",
                                                        fn: function(item) {
                                                          return [
                                                            _c(
                                                              "div",
                                                              {
                                                                class: {
                                                                  "tw-font-bold":
                                                                    item.isTotals
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.average_damages_per_report
                                                                  )
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        }
                                                      },
                                                      {
                                                        key: "payout_average",
                                                        fn: function(item) {
                                                          return [
                                                            _c(
                                                              "div",
                                                              {
                                                                class: {
                                                                  "tw-font-bold":
                                                                    item.isTotals
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.payout_average
                                                                  )
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    false,
                                                    4159919673
                                                  )
                                                })
                                              : _c("div", [
                                                  _vm._v(
                                                    "Geen resultaten gevonden."
                                                  )
                                                ])
                                          ],
                                          1
                                        )
                                      ]
                                    )
                                  ]
                                : _vm._e()
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }