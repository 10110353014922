import WeekPlanningComponent from '@/components/week-planning-component/WeekPlanningComponent.vue';
import { Component, Vue } from 'vue-property-decorator';
import axios, { AxiosError, AxiosResponse } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';
import { Report } from '@/models/Report';
import { Project as ProjectModel, projectTypes, ProjectType } from '@/models/Project';
import { Organization } from '@/models/Organization';
import PeTAT from '@/components/PeTAT/PeTAT.vue';
import { identifyHostname } from '@/support/Client';
import { MapArea } from '@/models/MapArea';
import { Token } from '@/support/Token';
import { ReportFilters } from '@/models/UserFilter';
import ReportPlanningStatusFilter from '@/components/filters/report-planning-status-filter/ReportPlanningStatusFilter.vue';
import PeTATLegenda from '@/components/PeTATLegenda/PeTATLegenda.vue';
import ReportDatatable from '@/components/ReportDatatable/ReportDatatable.vue';
import { Label } from '@/components/List/List';

import { Rpc } from '@/models/Rpc';
import { PermissionSlug } from '@/support/PermissionSlug';
import { PetatLegendaTypes } from '@/components/PeTAT/PeTAT';

@Component<Project>({
  filters: {
    parseProjectType: (type: string) => {
      const foundType = projectTypes.find((projectType: ProjectType) => projectType.value === type);

      return foundType ? foundType.name : '-';
    },
  },
  components: {
    PeTAT,
    WeekPlanningComponent,
    PeTATLegenda,
    ReportPlanningStatusFilter,
    ReportDatatable,
  },
})
export default class Project extends Vue {
  public $pageTitle = 'Project';

  protected project: ProjectModel | null = null;

  protected projectTypes: ProjectType[] = projectTypes;

  protected selectedOrganization: Organization | null = null;

  protected selectedMapArea: MapArea | null = null;

  protected highlightedReport: any | null = null;

  protected planningTableFilters: ReportFilters = {
    statuses: ['planning', 'back_to_planning'],
    planning_status: 'unplanned',
  };

  protected statuses: Record<string, string>[] = [
    {
      label: 'Opgeleverd',
      value: 'approved',
    },
    {
      label: 'Verstuurd aan Aanvrager',
      value: 'downloaded',
    },
    {
      label: 'Alle rapporten',
      value: 'reports',
    },
  ];

  protected selectedStatus = 'reports';

  protected legendaType = PetatLegendaTypes.PLANNING;

  protected tableSearchInput = '';

  protected isLoading = {
    page: true,
    project: true,
    reports: false,
    petat: true,
    statistics: true,
  };

  protected petatFiltersPlanning = {
    postcode: '9726AD',
    number: '10',
    number_add: '',
    magnitude_min: '1.0',
    magnitude_max: '6.0',
    pgv: '2.0',
    pct: '1',
    statuses: [],
    applicant_submitted_at: {},
    downloaded_at: {},
    payout_total: {
      from: 0,
      to: 1000000,
    },
    filterApplicantSubmittedAt: false,
    filterDownloadedAt: false,
    filterPayoutTotal: false,
  };

  // PETAT TOOL
  protected response: any | null = null;

  public oAuthServer: string = identifyHostname() || '';

  // TABS
  protected activeTab = 'planning';

  // TAB STATISTICS

  protected statisticsColumns: Label[] = [
    {
      slotName: 'name',
      title: '',
      width: 'xs1',
    },
    {
      slotName: 'report_count',
      title: 'Aantal dossiers',
      width: 'xs1',
      class: 'text-right',
      headerClass: 'text-right',
    },
    {
      slotName: 'damage_count',
      title: 'Aantal schades',
      width: 'xs2',
      class: 'text-right',
      headerClass: 'text-right',
    },
    {
      slotName: 'total_causal_damages',
      title: 'Aantal causale schades',
      width: 'xs2',
      class: 'text-right',
      headerClass: 'text-right',
    },
    {
      slotName: 'average_percentage_causal_damages',
      title: 'Gem. % causale schades per dossier',
      width: 'xs2',
      class: 'text-right',
      headerClass: 'text-right',
    },
    {
      slotName: 'average_damages_per_report',
      title: 'Gem. # schades per dossier',
      width: 'xs2',
      class: 'text-right',
      headerClass: 'text-right',
    },
    {
      slotName: 'payout_average',
      title: 'Gemiddeld schadebedrag',
      width: 'xs2',
      class: 'text-right',
      headerClass: 'text-right',
    },
  ];

  protected statisticsHousingTypeItems: null | StatisticsTableItem[] = null;

  protected statisticsOrganizationItems: null | StatisticsTableItem[] = null;

  // #endregion

  // #region Lifecycle hooks
  /**
  * ie.
  * protected mounted(): void {
  *  console.log('MyComponent has Mounted');
  * }
  */

  protected async mounted() {
    setTimeout(() => { this.isLoading.statistics = false; }, 1000);

    this.isLoading.page = true;

    await this.fetchProject();
    this.initBreadcrumb();

    this.isLoading.page = false;
  }

  // #endregion

  // #region Class methods: Hanlders
  /**
  * ie.
  * protected toggleLoading(): void {
  *  this.isLoading = ! this.isLoading;
  * }
  */

  protected onMouseEnterTableRow(report: Report) {
    this.highlightedReport = {
      id: report.uuid,
      lat: report?.address?.geolocation?.latitude,
      lng: report?.address?.geolocation?.longitude,
    };
  }

  protected onTabClicked(activeTab: string): void {
    if (activeTab === 'statistics') {
      this.initializeTabStatistics();
      return;
    }

    this.isLoading.statistics = true;
  }

  // #endregion

  // #region Class methods: Helpers
  /**
  * ie.
  * protected toggleLoading(): void {
  *  this.isLoading = ! this.isLoading;
  * }
  */

  protected getAxiosClient() {
    const headers = {};
    if (Token.get('access')) {
      (headers as any).Authorization = `Bearer ${Token.get('access')}`;
    }

    const axiosClient = axios.create({
      baseURL: this.oAuthServer,
      headers,
    });
    return axiosClient;
  }

  protected initBreadcrumb() {
    this.$root.$emit('breadcrumbUpdated',
      {
        crumb: [
          { name: 'Alle projecten', path: '/projecten' },
          { name: this.project ? this.project.name : '' },
        ],
      });
  }

  protected async initializeTabStatistics(): Promise<void> {
    this.isLoading.statistics = true;
    await this.fetchStatistics();
    this.isLoading.statistics = false;
  }

  protected parseToStatisticsTableItems(statistics: StatisticsItems): StatisticsTableItem[] {
    let statisticsTableItems: StatisticsTableItem[] = [];

    if (statistics.data) {
      statisticsTableItems = statistics.data.map((item: StatisticsItem): StatisticsTableItem => ({
        name: item.name || '',
        report_count: item.data?.report_count || 0,
        damage_count: item.data?.damage_count || 0,
        payout_average: item.data?.payout_average || 0,
        total_causal_damages: item.data?.total_causal_damages || 0,
        average_percentage_causal_damages: item.data?.average_percentage_causal_damages || 0,
        average_damages_per_report: item.data?.average_damages_per_report || 0,
      }));
    }

    if (statistics.totals) {
      const totals: StatisticsTableItem = {
        name: statistics.totals.name || '',
        report_count: statistics.totals.data?.report_count || 0,
        damage_count: statistics.totals.data?.damage_count || 0,
        total_causal_damages: statistics.totals.data?.total_causal_damages || 0,
        average_percentage_causal_damages: statistics.totals.data?.average_percentage_causal_damages || 0,
        average_damages_per_report: statistics.totals.data?.average_damages_per_report || 0,
        payout_average: statistics.totals.data?.payout_average || 0,
        isTotals: true,
      };

      statisticsTableItems = [...statisticsTableItems, totals];
    }

    return statisticsTableItems;
  }

  // #endregion

  // #region API requests
  /**
  * ie.
  * protected async fetchUserCompany(): Promise<void> {
  *  this.company = await new Company().filter({user: this.user.id}).all();
  * }
  */

  protected async fetchProject() {
    this.isLoading.project = true;
    if (! this.$route.params.id) {
      return;
    }

    this.project = null;

    this.project = await new ProjectModel()
      .include([
        'statistics',
        'organization',
        'map_area',
      ])
      .find(this.$route.params.id);

    if (this.project?.organization) {
      this.selectedOrganization = this.project.organization;
    }

    if (this.project && ! this.project?.map_settings) {
      this.project.map_settings = {
        center: {
          latitude: 1,
          longitude: 2,
        },
        zoom: 10,
      };
    }

    this.planningTableFilters.project = this.project?.id;
    this.isLoading.project = false;
  }

  protected async fetchStatistics(): Promise<void> {
    await Promise.all([
      this.fetchStatisticsHousingTypes(),
      this.fetchStatisticsOrganizations(),
    ]);
  }

  protected async fetchStatisticsHousingTypes(): Promise<void> {
    const payload: StatisticsRpcPayload = {
      signature: 'project:statistics-per-building-type',
      body: {
        project: this.project?.id || '',
      },
    };

    const statisticsResponse: null | AxiosResponse = await new Rpc()
      .rpcPost(payload, false)
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
        return null;
      });

    this.statisticsHousingTypeItems = statisticsResponse && statisticsResponse.data
      ? this.parseToStatisticsTableItems(statisticsResponse.data)
      : null;
  }

  protected async fetchStatisticsOrganizations(): Promise<void> {
    const payload: StatisticsRpcPayload = {
      signature: 'project:statistics-per-organization',
      body: {
        project: this.project?.id || '',
      },
    };

    const statisticsResponse: null | AxiosResponse = await new Rpc()
      .rpcPost(payload, false)
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
        return null;
      });

    this.statisticsOrganizationItems = statisticsResponse && statisticsResponse.data
      ? this.parseToStatisticsTableItems(statisticsResponse.data)
      : null;
  }

  protected async initializeEarthquakes(payload: any) {
    this.isLoading.petat = true;

    const client = this.getAxiosClient();

    await client
      .get(`/v1/tools/effectgebied?${payload}`)
      .then((response: AxiosResponse) => {
        this.response = response;
        this.isLoading.petat = false;
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  // #endregion

  // #region Getters & Setters
  /**
  * ie.
  * protected get companyName(): string {
  *  return this?.company.name || 'N/A';
  * }
  */

  protected get dataTableFilters() {
    switch (this.selectedStatus) {
      case 'reports':
        return {
          project: this.project?.id,
        };
      case 'approved':
        return this.dataTableFiltersClosed;
      case 'downloaded':
        return this.dataTableFiltersDelivered;

      default:
        break;
    }
  }

  protected get dataTableFiltersClosed() {
    return {
      project: this.project?.id,
      statuses: [
        'downloaded',
        'zienswijze_closed',
        'objection_closed',
        'smr_closed',
      ],
    };
  }

  protected get dataTableFiltersDelivered() {
    return {
      project: this.project?.id,
      statuses: [
        'approved', 'veldwerk_corrected',
        'zienswijze_approved', 'zienswijze_corrected',
        'objection_approved', 'objection_corrected', 'objection_pending_close',
        'smr_approved', 'smr_corrected',
      ],
    };
  }

  protected get tabs() {
    return [
      {
        name: 'Planning',
        key: 'planning',
      },
      {
        name: 'Weekplanning',
        key: 'in_progress',
      },
      {
        name: 'Alle rapporten',
        key: 'reports',
      },
      {
        name: 'Statistieken',
        key: 'statistics',
      },
    ];
  }

  protected get canUpdateProjects(): boolean {
    return this.$store.state.Auth?.can(PermissionSlug.PROJECTS_UPDATE);
  }

  // #endregion

  // #region @Watchers
  /**
  * ie.
  * @Watch('company')
  * protected companyChanged() {
  *  console.log('Company Changed') ;
  * }
  */
  // #endregion
}

// #region Enums, Interfaces & Types

interface StatisticsTableItem {
  name: string;
  report_count: number;
  damage_count: number;
  total_causal_damages: number;
  average_percentage_causal_damages: number;
  average_damages_per_report: number;
  payout_average: number;
  isTotals?: boolean;
}

interface StatisticsItemData {
  report_count?: number;
  damage_count?: number;
  total_causal_damages: number;
  average_percentage_causal_damages: number;
  average_damages_per_report: number;
  payout_average?: number;
}

interface StatisticsItem {
  name?: string;
  key?: string;
  data?: StatisticsItemData;
}

interface StatisticsItems {
  data: StatisticsItem[],
  totals: StatisticsItem,
}

interface StatisticsRpcPayload {
  signature: string;
  body: {
    project: string;
  };
}

// #endregion
